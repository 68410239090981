import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Box, IconButton, Heading, Image, Tooltip, useColorMode } from '@chakra-ui/core'
import { Case, Footer, Header } from '../components'
// import useScrollPos from '../hooks/useScrollPos'
import demos from '../const/demos'
import DemoStyles from '../components/styles/Demo'

export default function Demos() {
  const { colorMode, toggleColorMode } = useColorMode('light')
  // const scrollPos = useScrollPos()
  const [lang, setLang] = useState('EN')

  useEffect(() => {
    if ('light' !== colorMode) toggleColorMode()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DemoStyles>
      <Header
        colorMode={colorMode}
        toggleColorMode={toggleColorMode}
        lang={lang}
        setLang={setLang}
        demoLength={demos.length}
        pageName='demos'
      />

      <Box className='container'>
        <Heading id='top' size='lg'>
          Case Studies
        </Heading>
        <Box className='inner-container'>
          <Heading size='md'>Clients:</Heading>
          <Box className='flex-row'>
            {demos.map((item, i) => (
              <Tooltip label={item.short[lang]} key={item.header.EN}>
                <Link to={`/demos#${item.header.EN}`} >
                  <Image src={item.logo} className='action small-logo' />
                </Link>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Box>

      { demos.map((demo, i) => (
        <Case
          key={demo.header.EN}
          lang={lang}
          item={demo}
          index={i+1}
        />
      ))}

      <Link to={`/demos#top`} >
        <IconButton className='action up' variantColor="teal" aria-label='go to top'
          icon='arrow-up'
        />
      </Link>

      <Footer lang={lang} />
    </DemoStyles>
  );
}
