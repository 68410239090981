import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  #top {
    font-size: 4em;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #bdffec;
  }
  .container {
    width: 100%;
    margin-right: auto;
    text-align: right;
  }
  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .small-logo {
    margin: 1rem;
    height: 50px;
  }
  .up {
    border: none;
    position: fixed;
    right: 5%;
    bottom: 5%;
    opacity: 50%;
  }
`